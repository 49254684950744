var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card",class:_vm.$style.container},[_vm._m(0),_c('a-form',{staticClass:"mb-4",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['fullname', {rules: [
          {
            required: true,
            message: 'Please input your full name'
          } ]}]),expression:"['fullname', {rules: [\n          {\n            required: true,\n            message: 'Please input your full name'\n          },\n        ]}]"}],attrs:{"size":"large","placeholder":"Full Name"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['email', {rules: [
          {
            required: true,
            message: 'Please input your email address'
          },
          {
            pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
            message: 'Wrong email format'
          }
        ]}]),expression:"['email', {rules: [\n          {\n            required: true,\n            message: 'Please input your email address'\n          },\n          {\n            pattern: /^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$/g,\n            message: 'Wrong email format'\n          }\n        ]}]"}],attrs:{"size":"large","placeholder":"Email Address"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone', {rules: [{ required: true, message: 'Please input your phone number' }]}]),expression:"['phone', {rules: [{ required: true, message: 'Please input your phone number' }]}]"}],attrs:{"size":"large","placeholder":"Phone"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(
          [
            'username',
            {
              rules: [
                {
                  required: true,
                  message: 'Please input your username'
                },
                {
                  min: 5,
                  message: 'Please input at least 5 characters'
                },
                {
                  whitespace: true,
                },
                {
                  validator: _vm.usernameValidator,
                  message: 'Wrong username format'
                }
              ]
            }
          ]),expression:"\n          [\n            'username',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Please input your username'\n                },\n                {\n                  min: 5,\n                  message: 'Please input at least 5 characters'\n                },\n                {\n                  whitespace: true,\n                },\n                {\n                  validator: usernameValidator,\n                  message: 'Wrong username format'\n                }\n              ]\n            }\n          ]"}],attrs:{"size":"large","placeholder":"Username"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password', {rules: [{ required: true, message: 'Please input your password' }]}]),expression:"['password', {rules: [{ required: true, message: 'Please input your password' }]}]"}],attrs:{"size":"large","type":"password","placeholder":"Password"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password_confirmation', {rules: [
            {
              required: true,
              message: 'Please re-type your password'
            },
            {
              validator: _vm.passwordValidator,
              message: 'Password didn\'t match'
            }
          ]}]),expression:"['password_confirmation', {rules: [\n            {\n              required: true,\n              message: 'Please re-type your password'\n            },\n            {\n              validator: passwordValidator,\n              message: 'Password didn\\'t match'\n            }\n          ]}]"}],attrs:{"size":"large","type":"password","placeholder":"Re-type Password"}})],1),_c('a-button',{staticClass:"text-center w-100",attrs:{"type":"primary","size":"large","loading":_vm.loading,"htmlType":"submit"}},[_c('strong',[_vm._v("Sign Up")])])],1)],1),_c('div',{staticClass:"text-center pt-2 mb-auto"},[_c('span',{staticClass:"mr-2"},[_vm._v("Already have an account?")]),_c('router-link',{staticClass:"kit__utils__link font-size-16",attrs:{"to":"/auth/login"}},[_vm._v("Sign in")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-dark font-size-24 mb-4"},[_c('strong',[_vm._v("Create your account")])])}]

export { render, staticRenderFns }